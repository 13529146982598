import Service from "../Service";

const resource = "configurationcost/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(Cfc, requestID) {
        return Service.post(resource + "save", Cfc, {
            params: { requestID }
        });
    }
}