<template>
    <div>
        <v-row>
            <v-col>
                <s-crud
                    title="Configuración de Costos"
                    add
                    edit
                    remove
                    :config="this.config"
                    @save="save($event)"
                    :filter="filter">
                <template scope="props">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <s-switch
                                    @input="change(props.item)"
                                    label="Artículo No Registrado en SAP"
                                    value="1"
                                    v-model="props.item.CfcNotSap"
                                />
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" class="s-col-form" lg="3" md="3" sm="12">
                                <s-text v-if="ArticleNotInSap"
                                    label="Descripción de Artículo"
                                    v-model="props.item.CfcArtName"
                                    ref="focusCfcArtName"
                                >
                                </s-text>
                                <s-article-search v-else 
                                    v-model="objArticle"
                                    :placeholder="props.item.CfcArtCodeName"
                                    ref="objArticle"
                                ></s-article-search>
                            </v-col>
                            <v-col cols="12" class="s-col-form" lg="3" md="3" sm="12">
                                <s-select-definition
                                    v-model="props.item.TypeService"
                                    :def="1171"
                                    label="Tipo Servicio"
                                />
                            </v-col>
                            <v-col cols="12" class="s-col-form" lg="3" md="3" sm="12">
                                <s-text
                                    v-model="props.item.CfcCost"
                                    label="Costo"
                                    decimal
                                    ref="FocusCfcCost"
                                >
                                </s-text>
                            </v-col>
                            <v-col cols="12" class="s-col-form" lg="3" md="3" sm="12">
                                <s-select-definition 
                                    :def="1300"
                                    v-model="props.item.CfcStatus"
                                    label="Estado"
                                    ref="CfcStatus"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                </s-crud>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import _sConfigurationCost from "@/services/Sale/SleConfigurationCostService";
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";

export default {
    components:{
        sArticleSearch
    },
    props:{},
    data() {
        return {
            config:{
                title:"Configuración Costo",
                model:{
                    CfcID: "ID"
                },
                service: _sConfigurationCost,
                headers:[
                    {text:"ID", value:"CfcID"},
                    {text:"TypeService", value:"TypeServiceName"},
                    {text:"Cod. Item", value:"CfcArtCode"},
                    {text:"Item", value:"CfcArtCodeName"},
                    {text:"Costo", value:"CfcCost"},
                    {text:"Estado", value:"CfcStatusName"},
                ],
            },
            objArticle: null,
            ArticleNotInSap: false,
        }
    },
    methods:{
        change(item)
        {
            if(item.CfcNotSap == 1){
                console.log('cambioss ');
                this.ArticleNotInSap = true;
            }else{
                this.ArticleNotInSap = false;
            }
        },

        Initialize(){
            this.filter={};
        },

        save(item){ 
            if(!this.ArticleNotInSap){
                if(this.objArticle == null){
                    this.$fun.alert("Ingrese Artículo", "error");
                    return;
                }
                item.CfcArtCode = this.objArticle.ArtCode;
            }
            else{
                if(item.CfcArtName == ""){
                    this.$fun.alert("Ingrese Artículo", "error");
                    this.$refs.focusCfcArtName.focus();
                    return;
                }
            }

            if(item.CfcCost == 0){
                this.$fun.alert("Ingrese Costo", "error");
                //this.$refs.CfcArtName.focus();
                this.$refs.FocusCfcCost.focus();
                return;
            }
            
            item.save()
            .then(resp => {
                this.objArticle = null;
            });
        }
    },
    created(){
        this.filter={};
    }
}
</script>